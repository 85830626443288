import './bootstrap';

import $ from 'jquery';
window.$ = window.jQuery = $;

const feather = require('feather-icons');

(function($) {
    'use strict';
    feather.replace();
})(jQuery);